<template>
  <div>
    <div id="home">
      <Home/>
    </div>
    <v-container py-5 class="pb-30">
      <div class="pb-10"/>
      <v-layout wrap mb-5 v-for="(item, i) in items" :key="i">
        <Section :id="item.id">
          <v-divider v-if="item.id != 'home' && item.id != 'about'"/>
          <About v-if="item.component == 'About'"/>
          <Profile v-if="item.component == 'Profile'"/>
          <Services v-if="item.component == 'Services'"/>
          <Project v-if="item.component == 'Project'"/>
          <Contact v-if="item.component == 'Contact'"/>
          <Documents v-if="item.component == 'Documents'"/>
        </Section>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import About from './About'
import Profile from './Profile'
import Services from './Services'
import Project from './Project'
import Contact from './Contact'
import Documents from './Documents'
import Home from './Home'

export default {
  computed: {
    items () {
      let items = this.$t('toolbar')
      for (const item of items) {
        let path = item.to.split('/')
        item.component = path[1].substr(0, 1).toUpperCase() + path[1].slice(1)
        item.id = path[1]
      }
      return items
    }
  },
  components: {
    About,
    Profile,
    Services,
    Project,
    Contact,
    Documents,
    Home
  }
}
</script>

<style>
</style>
