<template>
  <v-container class="pt-4 mt-12">
    <v-layout align-center justify-center flex-column>
      <h1 v-if="$t().privacyPolicy">
        {{ $t('privacyPolicy.headerTitle') }}
      </h1>
    </v-layout>
    <v-container
      v-for="(item, i) in $t('privacyPolicy.items')"
      :key="i"
      :id="item.id"
    >
      <h2 v-if="item.title">{{ item.title }}</h2>
      <v-container v-if="item.img">

          <v-container class="private-properties">
            <div
              v-for="(prop, p) in item.properties"
              :key="p"
              class="private-container ma-2"
            >
              <v-row>
                <v-col cols="12" md="4" class="pa-0">
                  <strong>{{ prop.label }}:</strong>
                </v-col>
                <v-col cols="12" md="8" class="pa-0 pl-2">
                  <div v-html="prop.value" />
                </v-col>
              </v-row>
            </div>
          </v-container>
      
      </v-container>
      <v-container v-for="(desc, d) in item.descriptions" :key="d">
        <h4>{{ desc.title }}</h4>
        <div v-for="(paragraph, p) in desc.paragraph" :key="p">
          <p v-if="!paragraph.list" v-html="paragraph"></p>
          <ul v-if="paragraph.list">
            <li v-for="(list1, l1) in paragraph.list" :key="l1">
              <span v-html="list1.item"></span>
              <ul>
                <li v-for="(list2, l2) in list1.list" :key="l2">
                  <span v-html="list2.item"></span>
                  <div v-if="list2.text" v-html="list2.text"></div>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </v-container>
    </v-container>
  </v-container>
</template>

<script>
import i18n from '@/plugins/i18n';

export default {
  created: function () {
    if (i18n.locale !== 'hu') {
      this.$router.push('/');
    }
  },
};
</script>

<style>
.private-properties {
 
  display: flex;
  flex-direction: column;
  align-content: space-between;
  height: 100%;
}

.private-container {
  flex: 1 100%;
}

@media only screen and (min-width: 960px) {
  .private-image {
    height: 300px;
  }
}

a {
  text-decoration: none;
}
</style>
