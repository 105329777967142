<template>
  <v-container class="pa-12 mt-12">
    <h1>Visszaélés bejelentése</h1>
    <v-container>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-card class="pa-10 mb-10">
          <v-card-title>Bejelentő adatai</v-card-title>
          <v-card-text>
            <v-radio-group v-model="anonymous">
              <v-radio
                label="A bejelentés névvel ellátott"
                :value="false"
              ></v-radio>

              <transition name="fade">
                <div v-if="!anonymous">
                  <v-text-field
                    class="mb-4"
                    v-model="name"
                    label="Bejelentő neve"
                    :rules="requeriedRules"
                    required
                  ></v-text-field>
                  <v-text-field
                    class="mb-4"
                    v-model="address"
                    label="Bejelentő lakcíme"
                    :rules="addressRules"
                  ></v-text-field>
                  <v-text-field
                    class="mb-4"
                    v-model="notificationAddress"
                    label="Bejelentő értesítési címe"
                    :rules="addressRules"
                  ></v-text-field>
                  <v-text-field
                    class="mb-4"
                    v-model="email"
                    label="Bejelentő e-mail címe"
                    :rules="[...emailOrPhoneRules, ...emailRules]"
                    required
                  ></v-text-field>
                  <v-text-field
                    class="mb-4"
                    v-model="phone"
                    label="Bejelentő telefonszáma"
                    :rules="[...emailOrPhoneRules, ...phoneRules]"
                    required
                  ></v-text-field>
                </div>
              </transition>
              <v-radio label="A bejelentés anonim" :value="true"></v-radio>
            </v-radio-group>
          </v-card-text>
        </v-card>

        <v-card class="pa-10 mb-10">
          <v-card-title
            >Milyen kapcsolatban áll az SMP Solutions Zrt-vel?
            <span v-if="anonymous">&nbsp;(Nem kötelező)</span></v-card-title
          >
          <v-radio-group v-model="contactType" :rules="contactTypeRules">
            <v-radio
              class="mb-5"
              v-for="contactTypeItem in contactTypeItems"
              :key="contactTypeItem"
              :label="contactTypeItem"
              :value="contactTypeItem"
              @mouseup="reset(contactTypeItem)"
            ></v-radio>
          </v-radio-group>
        </v-card>
        <v-card class="pa-10 mb-10">
          <v-card-title>Milyen témában kíván bejelentést tenni?</v-card-title>
          <div class="theme-list">
            <v-checkbox
              v-for="(themeItem, j) in themeItems"
              :key="j"
              class="pa-0 mb-5"
              v-model="theme"
              :value="themeItem"
              :label="themeItem"
              :rules="requeriedTheme"
              required
            ></v-checkbox>
          </div>
        </v-card>

        <v-card class="pa-10 mb-10">
          <v-card-title>A bejelentés tartalma</v-card-title>
          <v-card-text>
            <v-textarea
              class="mb-4"
              v-model="details"
              rows="6"
              label="A bejelentés részletes leírása"
              :rules="requeriedRules"
            ></v-textarea>
            <span
              >Bejelentéssel érintett személy/szervezeti egység megnevezése,
              érintettek köre, az eset helye és ideje, leírása, bizonyítékok,
              egyéb tények, stb</span
            >
          </v-card-text>
        </v-card>

        <v-checkbox
          class="mb-4"
          v-model="inGoodFaith"
          label="Kijelentem, hogy a bejelentésemet jóhiszeműen teszem"
          :rules="requeriedRules"
          required
        ></v-checkbox>

        <v-checkbox
          class="mb-4"
          v-model="acceptedPrivatePolicy"
          :rules="requeriedRules"
          required
        >
          <template v-slot:label>
            <div>
              Kijelentem, hogy az
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <a
                    target="_blank"
                    href="./#/privacyPolicy"
                    @click.stop
                    v-on="on"
                  >
                    Adatkezelési Tájékoztató
                  </a>
                </template>
                Adatkezelési Tájékoztató megnyitása
              </v-tooltip>
              tartalmát megismertem és tudomásul vettem.
            </div>
          </template>
        </v-checkbox>

        <v-file-input
          label="Dokumentum csatolása"
          multiple
          v-model="files"
          :rules="fileRules"
          show-size
          class="pt-0 mb-10"
          ><template v-slot:selection="{ text }">
            <span color="deep-purple accent-4" class="mr-4">
              {{ text }}
            </span>
          </template>
        </v-file-input>
        <v-btn
          :loading="loading"
          :disabled="loading"
          @click="submit"
          class="primary mt-2"
        >
          Beküld
        </v-btn>
        <v-dialog v-model="dialog" width="500">
          <v-card>
            <v-card-text>
              <div class="pt-10">
                <div class="text-center">
                  <h2>{{ message }}</h2>
                </div>
              </div>
            </v-card-text>
            <v-card-actions class="justify-end"
              ><v-btn text @click="dialog = false">
                Rendben
              </v-btn></v-card-actions
            >
          </v-card>
        </v-dialog>
      </v-form>
    </v-container>
  </v-container>
</template>
<script>
import Vue from 'vue';
import { VueReCaptcha } from 'vue-recaptcha-v3';

export default {
  data: () => ({
    dialog: false,
    message: '',
    loading: false,
    valid: true,
    name: '',
    address: '',
    email: '',
    notificationAddress: '',
    phone: '',
    details: '',
    anonymous: false,
    files: [],
    requeriedTheme: [
      (value) => value.length > 0 || 'Kötelező egyet megjelölni',
    ],
    requeriedRules: [(value) => !!value || 'Kötelező'],
    emailRules: [
      (value) =>
        value === '' ||
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          value
        ) ||
        'Nem érvényes email cím',
    ],
    phoneRules: [
      (value) =>
        value === '' ||
        /^[+]?[0-9-/() ]{6,}$/.test(value) ||
        'Nem érvényes telefonszám (érvényes pl. +36201234567 +36(20)1234-567)',
    ],
    fileRules: [(files) => {
      let fileSize = 0;
      for (const file of files) {
        fileSize += file.size;
      }
      return fileSize < 25000000 || 'A csatolt dokumntumok mérete nem lehet 25MB-nál nagyobb';
    }],
    contactType: null,
    contactTypeItems: [
      'A Társaság jelenlegi munkavállalója',
      'A Társasággal való munkaviszonya megszűnt',
      'A Társasággal való munkaviszonyának létesítéséről szándéknyilatkozat történt',
      'A Társasággal szerződéses kapcsolatban álló egyéni cég, egyéni vállalkozó, szerződéses vállalkozó, alvállalkozó, megbízott, valamint az általuk foglalkoztatott személyek',
      'A Társasággal a múltban szerződéses kapcsolatban álló egyéni cég, egyéni vállalkozó, szerződéses vállalkozó, alvállalkozó, megbízott, valamint az általuk foglalkoztatott személyek',
      'A Társasággal való Partneri kapcsolatának létesítéséről szándéknyilatkozattal rendelkező egyéni cég, egyéni vállalkozó, szerződéses vállalkozó, alvállalkozó, megbízott, valamint az általuk foglalkoztatott személyek',
      'Gyakornok, önkéntes',
      'A Társaság részvényese, tulajdonosa',
      'A Társaság felügyeleti testületének tagja',
      'Egyéb',
    ],
    theme: [],
    themeItems: [
      'Pénzmosás',
      'Korrupció',
      'Etikai szabályok megszegése',
      'Vállalati tulajdon, vagyonvédelem',
      'Üzleti titokkal való visszaélés',
      'Tisztességtelen verseny',
      'Összeférhetetlenség',
      'Munkahelyi megfélemlítés, zaklatás, diszkrimináció',
      'Környezetvédelmi szabályok megszegése',
      'Egyéb',
    ],
    inGoodFaith: false,
    acceptedPrivatePolicy: false,
  }),
  computed: {
    addressRules() {
      const rules = [];
      if (this.address === '') {
        rules.push(
          () =>
            this.notificationAddress !== '' ||
            'Bejelentő lakcíme vagy értesítési címe kötelező'
        );
      }
      if (this.notificationAddress === '') {
        rules.push(
          () =>
            this.address !== '' ||
            'Bejelentő lakcíme vagy értesítési címe kötelező'
        );
      }
      return rules;
    },
    contactTypeRules() {
      const rules = [];
      if (this.anonymous === false) {
        rules.push(() => !!this.contactType || 'Kötelező');
      }
      return rules;
    },
    emailOrPhoneRules() {
      const rules = [];
      if (this.email === '') {
        rules.push(
          () =>
            this.phone !== '' ||
            'Bejelentő e-mail címe vagy telefonszáma kötelező'
        );
      }
      if (this.phone === '') {
        rules.push(
          () =>
            this.email !== '' ||
            'Bejelentő e-mail címe vagy telefonszáma kötelező'
        );
      }
      return rules;
    },
  },
  mounted: function () {
    this.getSiteKey();
  },
  methods: {
    getSiteKey() {
      const self = this;
      fetch('https://smpsite.azurewebsites.net/api/getsiteconfig', {
        method: 'GET',
      })
        .then((data) => {
          if (data.status !== 200) {
            self.message =
              'Sajnáljuk valami hiba történt, kérjük próbálja meg később';
            console.error('Nincs érvényes reCAPTCHA sitekey');
            self.dialog = true;
            return null;
          } else {
            return data.json();
          }
        })
        .then((response) => {
          Vue.use(VueReCaptcha, {
            siteKey: response?.reCaptchaSiteKey,
          });
        })
        .catch(() => {
          self.message =
            'Sajnáljuk valami hiba történt, kérjük próbálja meg később';
          console.error('Nincs érvényes reCAPTCHA sitekey');
          self.dialog = true;
        });
    },
    validate() {
      this.valid = this.$refs.form.validate();
    },
    submit() {
      this.validate();
      setTimeout(() => {
        let errorTexts = this.$el.getElementsByClassName('error--text');
        if (errorTexts.length > 0) {
          errorTexts[0].parentElement.scrollIntoView();
        }
      }, 0);
      if (this.valid) {
        this.loading = true;
        const formData = new FormData();
        for (const [i, file] of Object.entries(this.files)) {
          formData.append(`files[${i}]`, file, file.name);
        }
        if (this.anonymous === false) {
          formData.append('personName', this.name);
          formData.append('personAddress', this.address);
          formData.append('personEmail', this.email);
          formData.append(
            'personNotificationAddress',
            this.notificationAddress
          );
          formData.append('personPhone', this.phone);
        } else {
          formData.append('personName', 'anonymous');
        }
        formData.append(
          'personContactType',
          this.contactType ? this.contactType : '-'
        );
        formData.append('whistleBlowingTheme', this.theme);
        formData.append('details', this.details);
        formData.append('inGoodFaith', this.inGoodFaith);
        formData.append('acceptedPrivatePolicy', this.acceptedPrivatePolicy);

        const self = this;

        this.$recaptcha('login').then((recaptchaToken) => {
          formData.append('g-recaptcha-response', recaptchaToken);
          const requestOptions = {
            method: 'POST',
            'Content-Type': 'multipart/form-data',
            body: formData,
          };
          fetch(
            'https://smpsite.azurewebsites.net/api/report/abuse',
            requestOptions
          )
            .then((response) => {
              if (response.status === 200) {
                return response.json();
              } else {
                console.error(response);
                return null;
              }
            })
            .then((response) => {
              if (response !== undefined && response.isSuccess) {
                self.dialog = true;
                self.message = 'Köszönjük a bejelentést';
                self.clear();
              } else {
                console.error(response);
                self.dialog = true;
                self.message =
                  'Sajnáljuk valami hiba történt, kérjük próbálja meg később';
                self.loading = false;
              }
            })
            .catch(function (error) {
              console.error(error);
              self.dialog = true;
              self.message =
                'Sajnáljuk valami hiba történt, kérjük próbálja meg később';
              self.loading = false;
            });
        });
      }
    },
    clear() {
      this.$refs.form.reset();
      this.loading = false;
    },
    reset(value) {
      if (this.anonymous && this.contactType === value) {
        setTimeout(() => {
          this.contactType = null;
        }, 100);
      }
    },
  },
};
</script>
<style>
.v-input--has-state.error--text .v-label {
  animation: none !important;
}
.fade-enter-active,
.fade-leave-active {
  max-height: 500px;
  transition: max-height 0.5s ease-in, opacity 2s ease;
}
.fade-enter,
.fade-leave-to {
  max-height: 0;
  transition: max-height 0.5s ease-out, opacity 1s ease;
  overflow: hidden;
  opacity: 0;
}
a {
  text-decoration: none;
}
.theme-list .v-messages {
  display: none;
}
.theme-list .v-input:last-child .v-messages {
  display: block;
}
.grecaptcha-badge {
  top: 100px !important;
}
</style>
