<template>
  <v-container class="pt-4 mt-12">
    <v-container
      v-for="(item, i) in $t('securityPolicy.items')"
      :key="i"
      :id="item.id"
    >
      <h2 v-if="item.title">{{ item.title }}</h2>
      <div v-for="(paragraph, p) in item.paragraph" :key="p">
        <p v-if="!paragraph.list" v-html="paragraph"></p>
        <ul v-if="paragraph.list">
          <li v-for="(list1, l1) in paragraph.list" :key="l1">
            <span v-html="list1"></span>
          </li>
        </ul>
      </div>
    </v-container>
  </v-container>
</template>

<script>
import i18n from '@/plugins/i18n';

export default {
  created: function () {
    if (i18n.locale !== 'hu') {
      this.$router.push('/');
    }
  },
};
</script>
