<template>
  <v-container class="pt-4 mt-12">
    <v-container
      v-for="(item1, i) in $t('prospectus.items')"
      :key="i"
      :id="item1.id"
    >
      <h2 v-if="item1.title">{{ item1.title }}</h2>
      <v-container v-for="(item2, j) in item1.items" :key="j">
        <h3 v-if="item2.title">{{ item2.title }}</h3>
        <div v-for="(paragraph, p) in item2.paragraph" :key="p">
          <p v-if="!paragraph.list" v-html="paragraph"></p>
        </div>
        <v-container v-for="(item3, d) in item2.items" :key="d">
          <h4 v-if="item3.title">{{ item3.title }}</h4>
          <div v-for="(paragraph, k) in item3.paragraph" :key="k">
            <p v-if="!paragraph.list" v-html="paragraph"></p>
            <ul v-if="paragraph.list">
              <li v-for="(list1, l1) in paragraph.list" :key="l1">
                <span v-html="list1.item"></span>
                <ul>
                  <li v-for="(list2, l2) in list1.list" :key="l2">
                    <span v-html="list2.item"></span>
                    <div v-if="list2.text" v-html="list2.text"></div>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
          <v-container v-if="item3.image" class="pa-0 prospectus-properties">
            <h4 v-if="item3.image.title">{{ item3.image.title }}</h4>
            <v-container class="pa-4">
              <v-row>
                <v-col cols="12" md="8" class="prospectus-container">
                  <div class="ma-2">
                    <v-row v-for="(prop, p) in item3.image.properties" :key="p">
                      <v-col cols="12" sm="3" class="prospectus-item">
                        <strong v-if="prop.label !== ''">{{ prop.label }}:</strong>
                      </v-col>
                      <v-col cols="12" sm="9" class="prospectus-item">
                        <div v-html="prop.value" />
                      </v-col>
                    </v-row>
                  </div>
                </v-col>
                <v-col cols="12" md="4">
                  <v-img
                    class="prospectus-image"
                    v-if="item3.image"
                    v-bind:src="require('@/assets/' + item3.image.src)"
                  >
                  </v-img>
                </v-col>
              </v-row>
            </v-container>
          </v-container>
        </v-container>
      </v-container>
    </v-container>
  </v-container>
</template>

<script>
import i18n from '@/plugins/i18n';

export default {
  created: function () {
    if (i18n.locale !== 'hu') {
      this.$router.push('/');
    }
  },
};
</script>

<style>
.prospectus-container {
  display: flex;
  align-items: center;
}

.prospectus-container > div {
  width: 100%;
}

.prospectus-item {
  padding: 8px !important;
}

@media only screen and (min-width: 960px) {
  .prospectus-image {
    height: 250px;
  }
}

.prospectus-properties a {
  text-decoration: none;
}
</style>
