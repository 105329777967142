<template>
 <v-container id="documents" class="pt-10">
    <v-layout align-center justify-center>
      <h1>{{ $t('documents.headerTitle') }}</h1>
    </v-layout>

    <v-layout align-center justify-center v-for="(item, i) in $t('documents.docs')" :key="i">
    
        <v-btn 
         draggable="false"
         :active-class="'no-active'"
         text min-width="0"
         @click="$vuetify.goTo('#page-top')"
         :to="item.link">
         <span>{{ item.policyPageTitle }}</span>
        </v-btn>
  
      </v-layout>


    <v-layout align-center justify-center v-for="(item, i) in $t('documents.pdfs')" :key="i">
    
      <v-btn 
       draggable="false"
       :active-class="'no-active'"
       text min-width="0"
       @click="openPdfInNewTab(item.link)">
       <span>{{ item.textTitle }}</span>
      </v-btn>

    </v-layout>

    
    
    


 </v-container> 
</template>

<script>
export default {
    methods: {
        openPdfInNewTab(pdfUrl) {
            window.open(pdfUrl, '_blank');
        }
    }
}
</script>